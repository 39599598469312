import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Column } from "@ant-design/plots";

const BarChart = ({chartData}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // asyncFetch();
    setData(chartData);
  }, [chartData]);

  // const asyncFetch = () => {
  //   fetch(
  //     // "https://gw.alipayobjects  .com/os/antfincdn/8elHX%26irfq/stack-column-data.json"
  //     "https://gw.alipayobjects.com/os/bmw-prod/b21e7336-0b3e-486c-9070-612ede49284e.json"
  //   )
  //     .then((response) => response.json())
  //     .then((json)=>setData(json))
  //     .catch((error) => {
  //       console.log("fetch data failed", error);
  //     });
  // };
  const config = {
    data,
    isStack: true,
    xField: "created_at",
    yField: "value",
    seriesField: "unit_sl",
    label: {
      // 可手动配置 label 数据标签位置
      position: "middle",
      // 'top', 'bottom', 'middle'
      // 可配置附加的布局方法
      layout: [
        // 柱形图数据标签位置自动调整
        {
          type: "interval-adjust-position",
        }, // 数据标签防遮挡
        {
          type: "interval-hide-overlap",
        }, // 数据标签文颜色自动调整
        {
          type: "adjust-color",
        },
      ],
    },
  };

  return <Column {...config} />;
};

export default BarChart;
