import DangerStatus from "../components/dashboard/thresholdStatus/DangerStatus";
import SafeStatus from "../components/dashboard/thresholdStatus/SafeStatus";

export default function thresholdStatusCalculate(value, threshold_object) {
  const { min, max } = threshold_object;
  if (value < min || value > max) {
    return <DangerStatus/>;
  }
  if (value >= min && value <= max) {
    return <SafeStatus/>;
  }
}
