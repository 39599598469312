import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Area } from "@ant-design/plots";

import { Divider } from "rsuite";
import { useVoltChartDataFetchMutation } from "../../../../features/energy-meter/details/energyMeterDetailsApi";
import { useParams } from "react-router-dom";

function LineChart({chartData}) {
  const [data, setData] = useState([]);
  // const { meterId } = useParams();
  // const [voltChartDataFetch, { data: voltageDataResponse, isLoading }] =
  //   useVoltChartDataFetchMutation();

  useEffect(() => {
    // voltChartDataFetch({
    //   meter_id: meterId,
    // });
    // setData(voltageDataResponse?.Voltage);
    // asyncFetch();
    setData(chartData);
  }, [chartData]);
  // let voltageData = [];
  // let element = "";
  const config = {
    data,
    xField: "created_at",
    yField: "value",
    seriesField: "unit_sl",
    slider: {
      start: 0.1,
      end: 0.9,
    },
  };

  // if (!data) {
  //   element = <div>Loading....</div>;
  // }
  // if (data) {
  //   voltageData = voltageDataResponse?.Voltage;
  //   console.log(voltageData);

  //   element = <Area {...config} />;
  // }

  const asyncFetch = () => {
    // setData(voltageData);
    fetch(
      "https://gw.alipayobjects.com/os/bmw-prod/b21e7336-0b3e-486c-9070-612ede49284e.json"
    )
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  };

  return <Area {...config} />;
}

export default LineChart;
