import React from "react";
import LineChartContainer from "./LineChartContainer";
import RoomInfo from "./roomInfo/RoomInfo";
import SensorGaugeDataMain from "./sensorGaugeData/SensorGaugeDataMain";
import "./styles/roomDetailsMain.css";

function RoomDetailsMain() {
  return (
    <div>
      <div className="info-container">
        <div className="gauge-container">
          <SensorGaugeDataMain />
        </div>
        <div className="room-info-container">
          <RoomInfo />
        </div>
      </div>

      <LineChartContainer />
    </div>
  );
}

export default RoomDetailsMain;
